import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import useMediaPath from "../../hooks/useMediaPath.js"

import "./TabSwitcher.scss"

const TabSwitcher = ({ tabs, defaultTab, origin }) => {
    const location = useLocation()
    const [ redirected, setRedirected ] = useState(false)
    const navigate = useNavigate()
    const swiperRef = useRef(null)
    const mediaPath = useMediaPath()
    
    let activeTabIndex = tabs.findIndex(tab => tab.route === location.pathname)

    useEffect(() => {
        if (activeTabIndex === -1 && defaultTab) {
            if (!redirected) {
                setRedirected(true)
                navigate(defaultTab)
            } else if (origin) {
                navigate(origin)
            }
        }
    }, [ activeTabIndex ])

    useEffect(() => {
        /* istanbul ignore next */
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(activeTabIndex, 150)
        }
    }, [ activeTabIndex ])

    if (!tabs.length) {
        return
    }

    return (
        <div className={"tab-switcher-container"}>
            <div className="tab-switcher">
                <div className="swiper-button-prev">
                    <img alt="Précédent" src={mediaPath([
                        "/assets/icons/arrow-left-orange.svg",
                        "/assets/icons/arrow-right-orange.svg",
                        "/assets/icons/business/arrow-blue.svg",
                    ])}/>
                </div>
                <Swiper
                    modules={[ Navigation ]}
                    slidesPerView={"auto"} // Number of tabs to show at once
                    navigation={
                        {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        }
                    }
                    updateOnWindowResize={true}
                    freeMode={true}
                    ref={swiperRef}
                >
                    {tabs.map((tab, index) => (
                        <SwiperSlide key={index}>
                            <NavLink
                                data-testid="swiperSlide"
                                className={({ isActive }) =>
                                    isActive ? "tab active" : "tab"
                                }
                                to={tab.route}
                            >
                                {tab.name}
                            </NavLink>
                        </SwiperSlide>
                    ),
                    )}
                </Swiper>
                <div className="swiper-button-next">
                    <img alt="Précédent" src={mediaPath([
                        "/assets/icons/arrow-right-orange.svg",
                        "/assets/icons/arrow-right-orange.svg",
                        "/assets/icons/business/arrow-blue.svg",
                    ])}/>
                </div>
            </div>
        </div>
    )
}

TabSwitcher.propTypes = {
    defaultTab: PropTypes.string,
    origin: PropTypes.string,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        route: PropTypes.string.isRequired,
    })).isRequired,
}

export default TabSwitcher
