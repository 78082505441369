import classNames from "classnames"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"

import "./TeamPicture.scss"

export const TeamPicture = ({ image, onClick, size, placeHolder = USER_PLACEHOLDER }) => {
    const [ imgSrc, setImgSrc ] = useState(image)

    const handleImageError = (e) => {
        e.stopPropagation()
        setImgSrc(null)
    }

    useEffect(() => {
        setImgSrc(image)
    }, [ image ])

    return (
        <div className={classNames("team-picture", {
            action: Boolean(onClick),
        })} onClick={onClick}  style={{ width: `${size}px` }}>
            {imgSrc ? (
                <img src={imgSrc} data-testid="teamLogo" alt="team logo" onError={handleImageError}/>
            ) : (
                <div className="blazon-placeholder">
                    <img src={placeHolder} alt="Photo de profil"/>
                </div>
            )}
        </div>
    )
}

const USER_PLACEHOLDER = "/assets/icons/league/blazonPlaceholder.svg"

TeamPicture.propTypes = {
    editCallback: PropTypes.func,
    image: PropTypes.string,
    onClick: PropTypes.func,
    placeHolder: PropTypes.string,
    size: PropTypes.number,
}
