import classNames from "classnames"
import dayjs from "dayjs"
import { bool, node, object, oneOfType } from "prop-types"
import { useMemo } from "react"

import {
    determineForfeitMessage,
    getMvpPlayer,
    isGameForfeit,
} from "../../../pages/league/service/service.js"
import { useAssets } from "../../../providers/AssetsProvider.jsx"
import ButtonCta from "../../Buttons/button/button-cta.jsx"
import LeagueMVP from "../LeagueMVP/LeagueMVP.jsx"
import VersusDisplay from "../VersusDisplay/VersusDisplay"

import "./MatchRecapCard.scss"

export default function MatchRecapCard({ match, team, openMvpModal }) {
    const mvpPlayer = getMvpPlayer(match)
    const { m } = useAssets()

    const recapAction = useMemo(() => {
        return (mvpPlayer) ? (
            <LeagueMVP player={mvpPlayer} />
        ) : match?.isMvpVoteOpen ? (
            <ButtonCta isAlt text={match?.mvpInfos?.selectedPlayer ? "Modifier mon vote" : "Vote pour l’homme du" +
                " match"} onClick={() => openMvpModal()} />
        ) : null
    }, [ match ])

    return (
        <div className={classNames("cardWrapper matchRecapCard", { action: !!recapAction })}>
            <div className="top">
                <img className="deco" src={m("icons.league.topRightGreyDeco")} />
            </div>
            <div className="header">
                <div className="start">
                    <div>
                        <img src={m("icons.league.calendar")} className="date" />
                        <span>{dayjs(match?.date).format("ddd DD/MM/YYYY")}</span>
                    </div>
                    <div>
                        <img src={m("icons.league.clock")} className="hour" />
                        <span>{dayjs(match?.date).format("HH[H]mm")}</span>
                    </div>
                </div>
                <div className="center">
                    <img className="cent" src={m("icons.league.center")} />
                    <div>{match?.center} Puteaux</div>
                </div>
            </div>
            <VersusDisplay
                big
                thunder
                team1={match.localTeam}
                team2={match.visitorTeam}
                isMatchPlayed={dayjs(match?.date).isBefore(dayjs()) || isGameForfeit(match)}
            />
            {isGameForfeit(match) && (
                <div className="forfeitMessage">
                    {determineForfeitMessage(match, team.id)}
                </div>
            )}
            {recapAction && !isGameForfeit(match) && (
                <div className="actionWrapper">
                    {recapAction}
                </div>
            )}
        </div>
    )
}

MatchRecapCard.propTypes = {
    action: oneOfType([ node, bool ]),
    match: object,
    openMvpModal: oneOfType([ node, bool ]),
    team: object,
}
