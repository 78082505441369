import classNames from "classnames"
import { bool, func, object, oneOfType, string } from "prop-types"
import React from "react"

import "./TextArea.scss"

const TextArea = React.forwardRef(
    function textArea(
        {   
            isEmpty,
            defaultValue,
            labelText,
            value,
            onChange,
            placeholder = "",
            maxLength,
            isRequired = false,
            onClick,
            error,
        },
        ref,
    ) {
        return (
            <div className={"textarea-root-container"}>
                <div
                    className={classNames("c-inputHolder c-inputHolder--textarea", {
                        empty: isEmpty,
                        error: error,
                    })}>
                    <div className="text-area">
                        {labelText && (
                            <label className="text-area-label">
                                {labelText}
                                {isRequired &&
                                    <span className="label-star">*</span>
                                }
                            </label>
                        )}
                        <textarea
                            defaultValue={defaultValue}
                            ref={ref}
                            className={"textArea"}
                            maxLength={maxLength}
                            onChange={
                                (e) => {
                                    if (onChange) {
                                        onChange(e.target.value)
                                    }
                                }
                            }
                            onClick={onClick ?? onClick}
                            value={value}
                            placeholder={placeholder}
                        />
                    </div>
                </div>
                {error && (
                    <small className="textarea-error">{error}</small>
                )}
            </div>
        )
    },
)

TextArea.propTypes = {
    defaultValue: object,
    error: string,
    isEmpty: bool,
    isRequired: bool,
    labelText: string,
    maxLength: string,
    onChange: func,
    onClick: oneOfType([ bool, func ]),
    placeholder: string,
    ref: string,
    value: string,
}

export default TextArea
