import { _apiCall } from "../backend/axios"
import { loadUserFriends, loadUserInvitations } from "../store/actions/content/index"
import { loadUser } from "../store/actions/scaffold/index"
import store from "../store/store"

export function createUserPlayerProfile(id, data) {
    return _apiCall(
        `create/users/${id}/playerprofile`,
        "POST",
        {
            "Content-Type": "application/json",
        },
        data,
    ).then(
        (response) => {
            return response?.data?.data
        },
    )
}

export function getUserPlayerProfile(id) {
    return _apiCall(
        `read/users/${id}/playerprofile`,
        "GET",
    ).then(
        (response) => {
            return response?.data?.data
        },
    )
}
export function postContact(contact, theme) {
    return _apiCall(
        "create/contacts",
        "POST",
        {
            "Content-Type": "application/json",
            activity: theme,
        },
        contact,
    ).then(
        (response) => {
            return response?.data?.data
        },
    )
}
export function modifyUserPlayerProfile(id, profil) {
    return _apiCall(
        `modify/users/${id}/playerprofile`,
        "PUT",
        {
            "Content-Type": "application/json",
        },
        profil,
    ).then(
        (response) => {
            return response?.data?.data
        },
    )
}
export function getContactPlayerProfile(id) {
    return _apiCall(
        `read/contacts/${id}/playerprofile`,
        "GET",
    ).then(
        (response) => {
            return response?.data?.data
        },
    )
}

export function modifyContactPlayerProfile(id, profil) {
    return _apiCall(
        `modify/contacts/${id}/playerprofile`,
        "PUT",
        {
            "Content-Type": "application/json",
        },
        profil,
    ).then(
        (response) => {
            return response?.data?.data
        },
    )
}

export function getUser(refetchProfileOnly = false) {
    return _apiCall(
        "read/current-user",
        "GET",
    ).then(
        async (response) => {
            if (response?.data?.data?.id) {
                await getUserPlayerProfile(response.data.data.id).then(res => {
                    response.data.data.playerProfile = res
                    store.dispatch(
                        loadUser(response.data, refetchProfileOnly),
                    )
                })
            }
            return response
        },
    )
}

export function getUsCentersBookable(theme) {
    return _apiCall(
        "read/us/centers/bookable",
        "GET",
        { activity: theme },
    ).then((response) => {
        return response?.data
    })
}

export function getUsCenters(activity) {
    return _apiCall(
        "read/us/centers",
        "GET",
        { activity },
    ).then(
        (response) => {
            return response?.data ? response.data : []
        },
    )
}

export function getUsCentersArea() {
    return _apiCall(
        "read/us/centersarea",
        "GET",
    ).then(
        (response) => {
            return response?.data ? response.data : []
        },
    )
}
export function listExhibitionVideos(gameId, theme) {
    return _apiCall(
        "read/exhibition/game/" + gameId + "/videos",
        "GET",
        theme,
    ).then((response) => {
        if (response?.data) {
            return response.data.data ? response.data.data : []
        }
    })
}

export function sendPasswordEmail(email) {
    return _apiCall(
        "read/password/send/email",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        {
            email: email,
        },
    ).then((response) => {
        return response
    })
}

export function accountVerify(token) {
    return _apiCall(
        "modify/account/verify",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        {
            token: token,
        },
    ).then((response) => {
        return response
    })
}

export function reservationInvitationsSend(data) {
    return _apiCall(
        "create/reservation/invitations/send",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then((response) => {
        return response
    })
}

export function getUsCards() {
    return _apiCall(
        "read/profil/payment/cards/us",
        "GET",
    ).then((response) => {
        return response
    })
}

export function getCreditCard() {
    return _apiCall(
        "read/profil/payment/card/credit",
        "GET",
    ).then((response) => {
        return response
    })
}

export function handleSplitPaymentToken(token) {
    return _apiCall(
        "read/payment/splitlink/" + token,
        "GET",
    ).then((response) => {
        if (response?.data?.data) {
            return response.data.data ? response.data.data : []
        }
        return []
    })
}

export function retrieveLocationPublicInvitDetails(locationId) {
    return _apiCall(
        "read/reservation/" + locationId + "/public/details",
        "GET",
    ).then((response) => {
        if (response?.data?.data) {
            return response.data.data ? response.data.data : []
        }
        return []
    })
}

export function generateLocationPublicInvitDetails(locationId) {
    return _apiCall(
        "create/reservation/public/details",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        {
            id: locationId,
        },
    ).then((response) => {
        if (response?.data?.data) {
            return response.data.data ? response.data.data : []
        }
        return []
    })
}

export function generatePayzenSplitPaymentTokenLink(data) {
    return _apiCall(
        "create/payment/card/credit/payment/splitlink",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then((response) => {
        if (response?.data?.data) {
            return response.data.data ? response.data.data : []
        }
        return []
    })
}

export function generatePayzenPaymentReqs(data) {
    return _apiCall(
        "create/profil/payment/card/payment/reqs",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then(
        (response) => {
            return response?.data?.data ?? response?.data ?? []
        },
    )
}

export function generatePayzenAliasRegisterReqs() {
    return _apiCall(
        "modify/profil/payment/card/credit",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
    ).then((response) => {
        if (response?.data?.data) {
            return response.data.data ? response.data.data : []
        }
        return []
    })
}

// persistPayzenCbAlias
export function persistPayzenCbAlias(data) {
    return _apiCall(
        "modify/profil/payment/card/credit/alias",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then((response) => {
        return response
    })
}

//
export function acceptLocationPrivateInvit(token) {
    return _apiCall(
        "modify/location/private/invit/accept",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        {
            token: token,
        },
    ).then((response) => {
        if (response?.data) {
            return response.data.data ? response.data.data : []
        }
    })
}

// modify/kids/stage/{id}/cancel

export function cancelStageRegistration(id) {
    return _apiCall(
        "modify/kids/stage/" + id + "/cancel",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        {},
    ).then((response) => {
        if (response.data.data) {
            return response.data.data ? response.data.data : [].subscriptions
        } else {
            return null
        }
    })
}

export function acceptConvocation(id) {
    return _apiCall(
        "modify/convocation/" + id + "/accept",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
    ).then((response) => {
        if (response?.data) {
            return response.data.data ? response.data.data : []
        }
    })
}

export function declineConvocation(id) {
    return _apiCall(
        "modify/convocation/" + id + "/decline",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
    ).then((response) => {
        if (response?.data) {
            return response.data.data ? response.data.data : []
        }
    })
}

export function getLocation(id) {
    return _apiCall(
        "read/location/" + id,
        "GET",
    ).then((response) => {
        if (response?.data?.data && !response.data.data.Message && !response.data.data.publicJoinToken) {return generateLocationPublicJoinToken(id).then(res => {
            if (res) {return res}
        })}
        else {return response?.data?.data ?
            response.data.data
            :
            []
        }
    })
}

export function getTokenLocation(token) {
    return _apiCall(
        "read/invitations/reservations",
        "POST",
        { "Content-Type": "application/json" },
        { token },
    ).then((response) => {
        return response?.data.data
    })
}

export function generateLocationPublicJoinToken(id) {
    return _apiCall(
        "modify/token/public/location/" + id,
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
    ).then((response) => {
        if (response?.data) {
            return response.data.data ? response.data.data : []
        }
    })
}

export function deleteCreditCard() {
    return _apiCall(
        "delete/profil/payment/card/credit",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
    ).then((response) => {
        return response
    })
}

export function deleteFriendshipRelation(id) {
    return _apiCall(
        "delete/profil/friendship/" + id,
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
    ).then((response) => {
        return response
    })
}

// handleLocationToken
export function handleLocationInvitation(id) {
    return _apiCall(
        "modify/location/invitation/public/join",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        { "publicJoinToken": id },
    ).then((response) => {
        return response
    })
}

export function handleFriendshipToken(token, withLeagueInvitation) {
    let resource = "create/profil/invitation/handle/token"
    if (withLeagueInvitation === true) {
        resource = "create/profil/invitation/friend-team/handle/token"
    }

    return _apiCall(
        resource,
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        { "token": token },
    ).then((response) => {
        return response
    })
}

export function refuseFriendshipInvitationToken(token) {
    return _apiCall(
        "modify/profil/invitation/refuse/token",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        { "token": token },
    ).then((response) => {
        return response
    })
}

/**
 *
 * @param data - {tokenBaseUrl}
 * @returns {*|Promise.<TResult>}
 */
export function generateFriendshipRawInvitationTokenUrl(tokenBaseUrl, teamSubscriptionId, cupSubscriptionId, teamId) {
    let data = { cupSubscriptionId, teamId, teamSubscriptionId, tokenBaseUrl }
    Object.keys(data).forEach((param) => (data[param] === null || data[param] === undefined || data[param] === "") && delete data[param])
    return _apiCall(
        "create/profil/invitation/friend/token/generate/url",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then((response) => {
        return response
    })
}

// create/profil/invitation/friend/username
export function createFriendshipInvitationFromUsername(username, linkedTeamSubscription, theme) {
    let resource = linkedTeamSubscription ? "create/profil/invitation/friend-team/username" : "create/profil/invitation/friend/username"
    let data = { username: username }
    if (linkedTeamSubscription) {
        data.linkedTeamSubscription = linkedTeamSubscription
    }
    return _apiCall(
        resource,
        "POST",
        {
            "Content-Type": "multipart/form-data",
            activity: theme,
        },
        data,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
    ).then((response) => {
        return response
    })
}

export function createFriendshipInvitationFromEmail(email, tokenBaseUrl, linkedTeamSubscription, theme) {
    let resource = linkedTeamSubscription ? "create/profil/invitation/friend-team/email" : "create/profil/invitation/friend/email"
    let data = { email: email, tokenBaseUrl: tokenBaseUrl }
    if (linkedTeamSubscription) {
        data.linkedTeamSubscription = linkedTeamSubscription
    }
    return _apiCall(
        resource,
        "POST",
        {
            "Content-Type": "multipart/form-data",
            activity: theme,
        },
        data,
    ).then((response) => {
        return response
    })
}

export function listUserFriendsAndInvitations() {
    const userId = store.getState().userLoaded.contactId
    return _apiCall(
        "read/profil/friendsAndInvitations",
        "GET",
    ).then((response) => {
        let data = response?.data?.data ? response.data.data : []
        if (data?.friends?.filter(friend => friend.ozmoSystemId === userId).length) {deleteFriendshipRelation(userId)}
        store.dispatch(
            loadUserFriends(data.friends),
        )
        store.dispatch(
            loadUserInvitations(data.invitations),
        )
        return { ...response, data: { ...response.data, data: { ...response.data.data, friends: data?.friends?.filter(friend => friend.ozmoSystemId !== userId) } } }
    })
}

export function getOzmoNotificationToken(userId) {
    return _apiCall(
        `read/mobile/user/notification_token/${userId}`,
        "GET",
    ).then(
        (response) => {
            return response
        },
    )
}

export function postNotificationTokenToOzmo(userId, token) {
    return _apiCall(
        `read/mobile/user/notification_token/${userId}`,
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        {
            value: token,
        },
    ).then(
        (response) => {
            return response
        },
    )
}

export function getNotificationSettings(userId) {
    return _apiCall(
        `read/mobile/user/notification_settings/${userId}`,
        "GET",
    ).then(
        (response) => {
            return response
        },
    )
}

export function updateNotificationSettings(userId, newValidatedIds) {
    return _apiCall(
        `read/mobile/user/notification_settings/${userId}`,
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        {
            ids: newValidatedIds,
        },
    ).then(
        (response) => {
            return response
        },
    )
}
export async function printPrepayment(id) {
    let call = await _apiCall(
        "read/payments/prepayments/" + id + "/print",
        "GET",
        null,
        null,
        true,
        true,
    )
    return call
}

export async function printCreditPrepayment(id) {
    return await _apiCall(
        "read/payments/creditPrepayments/" + id + "/print",
        "GET",
        null,
        null,
        true,
        true,
    )
}

export async function printCommand(id) {
    let call = await _apiCall(
        "read/payments/commands/" + id + "/print",
        "GET",
        null,
        null,
        true,
        true,
    )
    return call
}
export function getCommands() {
    return _apiCall(
        "read/payments/commands",
        "GET",
        { activity: 2 },
    ).then((response) => {
        if (response?.data?.data) {
            return response.data.data.items ? response.data.data.items : []
        }
        return []
    })
}

export function getBill(billId) {
    return _apiCall(`read/payments/commands/${billId}`, "GET")
        .then(response => response.data.data)
}

export function getDebt(debtId) {
    return _apiCall(`read/profil/debt/${debtId}`, "GET")
        .then(response => response.data.data)
}

export async function printBill(billId) {
    return await _apiCall(
        "read/profil/payment/facture/" + billId + "/print",
        "GET",
        {},
        null,
        true,
    )
}

export async function displayBill(billId, setIsLoading) {
    setIsLoading(true)
    let data = await printBill(billId)

    if (data) {
        setIsLoading(false)
    }
}

export function getFactures(data) {
    return _apiCall(
        "read/profil/payment/factures",
        "GET",
        {},
        data,
    ).then((response) => {
        if (response?.data?.data) {
            return response.data.data ? response.data.data.items : []
        }
        return []
    })
}

export function getArdoises() {
    return _apiCall(
        "read/profil/payment/ardoises",
        "GET",
    ).then((response) => {
        if (response?.data?.data) {
            return response.data.data ? response.data.data : []
        }
        return []
    })
}

export function getAbonnements(theme) {
    return _apiCall(
        "read/profil/abonnements",
        "GET",
        theme,
    ).then((response) => {
        if (response?.data?.data) {
            return response.data.data ? response.data.data : []
        }
        return []
    })
}

export function getAbonnement(id) {
    return _apiCall(
        "read/profil/abonnement/" + id,
        "GET",
    ).then((response) => {
        if (response?.data?.data) {
            return response.data.data ? response.data.data : []
        }
        return []
    })
}

export function getUserKids() {
    return _apiCall(
        "read/kids",
        "GET",
    ).then((response) => {
        if (response) {
            return response.data.data ? response.data.data : []
        }
        return response
    })
}

/**
 * @param firstName
 * @param lastName
 * @param birthDate format : d/m/Y
 * @param genderValue
 */
export function addUserKid(kid) {
    return _apiCall(
        "modify/kids/add",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        kid,
    ).then((response) => {
        if (response?.data) {
            return response.data.data ? response.data.data : []
        }
    })
}

export function editUserKid(id, kid) {
    return _apiCall(
        "modify/kids/" + id + "/edit",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        kid,
    ).then((response) => {
        if (response?.data) {
            return response.data.data ? response.data.data : []
        }
    })
}

// read/kids/stages
export function getKidsStages(params) {
    let uri = "read/kids/stages"
    if (Object.keys(params).length > 0) {
        uri += "?"
    }
    Object.keys(params).forEach((key, idx) => {
        uri += (key + "=" + params[key] + (idx === Object.keys(params).length - 1 ? "" : "&"))
    })

    return _apiCall(
        uri,
        "GET",
    ).then((response) => {
        if (response?.data) {
            return response.data.data ? response.data.data : []
        }
    })
}

export function getNextLocation() {
    return _apiCall(
        `read/locations?periodStart=${new Date().toISOString()}&periodEnd=${null}&orderByAsc=${true}&invitationStatuses[]=1&invitationStatuses[]=0&statuses[]=0`,
        "GET",
    ).then((res) => {
        return res.data.data ? res.data.data.items : []
    })
}

export function getAllKidsSchoolsSubscriptions(periodStart, periodEnd, status, orderByAsc, offset, setHitcount, theme) {
    let queryParams = ""

    if (periodStart) {
        queryParams += "?periodStart=" + periodStart
    }
    if (periodEnd) {
        queryParams += (queryParams.length === 0 ? "?" : "&") + "periodEnd=" + periodEnd
    }
    if (status) {
        queryParams += (queryParams.length === 0 ? "?" : "&") + "status=" + status
    }
    if (orderByAsc !== null) {
        queryParams += (queryParams.length === 0 ? "?" : "&") + "orderByAsc=" + orderByAsc
    }
    // if (pagesize) {
    //     queryParams += (queryParams.length === 0 ? "?" : "&") + "pagesize=" + pagesize;
    // }
    if (offset) {
        queryParams += (queryParams.length === 0 ? "?" : "&") + "offset=" + offset
    }
    return _apiCall(
        "read/kids/school/subscriptions/all" + queryParams,
        "GET",
        { activity: theme },
    ).then((response) => {
        if (setHitcount && response?.data?.data?.hitCount) {setHitcount(response.data.data.hitCount)}
        return response?.data?.data?.items ?? response.data.data
    })
}

/**
 * @param id subscription id to apply the discount
 * @param code the discount code
 */
export function addSchoolDiscountCode(id, code) {
    return _apiCall(
        "modify/kids/school/subscription/" + id + "/discountCode/add",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        { code: code.toUpperCase() },
    ).then((response) => {
        return response
    })
}

export function deleteSchoolDiscountCode(id, code) {
    return _apiCall(
        "modify/kids/school/subscription/" + id + "/discountCode/delete",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        { code: code.toUpperCase() },
    ).then((response) => {
        return response
    })
}

// SCHOOL
export function deleteSchoolProduct(id, productId, size) {
    return _apiCall(
        "modify/kids/school/" + id + "/product/delete/" + productId + "/" + size,
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
    ).then((response) => {
        if (response?.data) {
            return response.data.data ? response.data.data : []
        }
    })
}

/**
 * Retourne une liste d'écoles
 */
export function getKidsSchools(params) {
    let uri = "read/kids/schools/list"
    if (Object.keys(params).length > 0) {
        uri += "?"
        Object.keys(params).forEach((key, idx) => {
            uri += (key + "=" + params[key] +
                (idx === Object.keys(params).length - 1 ? "" : "&"))
        })
    }

    return _apiCall(
        uri,
        "GET",
    ).then((response) => {
        if (response) {
            return response.data.data ? response.data.data : []
        } else {
            return response
        }
    })
}

/**
 * Retourne les informations concernant une école donnée par son identifiant
 */
export function getKidsSchoolDetail(id) {
    return _apiCall(
        "read/kids/school/" + id,
        "GET",
    ).then((response) => {
        return response.data
    })
}

/**
 * @param child id of the child
 * @param childFirstname
 * @param childLastname
 * @param childBirthDate format : d/m/Y
 * @param childGender
 * @param options
 * @param correlationId id of the second subscription
 * @param waitingForAvailability
 * @param contact1Firstname
 * @param contact1Lastname
 * @param contact1Phone
 * @param contact1Email
 * @param contact2Firstname
 * @param contact2Lastname
 * @param contact2Phone
 * @param contact2Email
 * @param subscriptionId
 * @param dailySlot
 * @param informations
 */
export function editSchoolSubscription(data) {
    return _apiCall(
        "modify/kids/school/edit",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then((response) => {
        return response.data
    })
}

export function addOptionToSchool(id, optionId) {
    return _apiCall(
        "modify/kids/school/" + id + "/option/add",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        { option: optionId },
    ).then((response) => {
        if (response !== undefined) {
            if (response.data !== undefined) {
                return response.data.data ? response.data.data : []
            }
        }
        return response
    })
}

// modify/league/team/registration/preregister/cancel
export function cancelPreregisterLeagueSubscription(id) {
    return _apiCall(
        "modify/league/team/registration/preregister/cancel",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        { registrationId: id },
    ).then((response) => {
        if (response?.data) {
            return response.data.data ? response.data.data : []
        }
    })
}

export function getKidsSchoolSubscription(id) {
    return _apiCall(
        "read/kids/school/subscription/" + id,
        "GET",
    ).then((response) => {
        if (response?.data) {
            return response.data.data ? response.data.data : []
        }
    })
}

export function getAvoirAmount(targetDate) {
    const data = {}

    if (targetDate) {
        data.targetDate = targetDate
    }
    
    return _apiCall(
        "read/bills/assignablecreditbills",
        "POST",
        {
            "Content-Type": "multipart/form-data",
        },
        data,
    ).then(
        (result) => {
            return Math.abs(result.data.data)
        },
    )
}

export async function getShareCalculation(id, parts) {
    return _apiCall(
        `read/shareCalculation?prereservationId=${id}&divisor=${parts}`,
        "GET",
    ).then(
        (result) => {
            return result?.data?.data
        },
    )
}

export async function getAvailableCredits() {
    return _apiCall(
        "read/profile/availableCredits",
        "GET",
    ).then(
        (result) => {
            return result?.data?.data
        },
    )
}
