/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */

function initializeMenuBusinessVisitor() {
    const menuslevel1 = {
        home: {
            slug: "accueil",
            label: "Accueil",
            img: "/assets/images/business/logo-business.png",
            desktopSection: "logo-part",
            externalLink: "https://www.businessleague.fr/",
        },
        reserver: {
            slug: "profil",
            label: "Connexion",
            desktopSection: "other-part",
            mobileSection: "main",
            appRoute: "/login",
            icon: "userProfile.svg",
            customCls: "reserver-menu-item",
        },
        informationsLegales: {
            slug: "informationsLegales",
            label: "Informations légales",
            mobileSection: "menu",
            customCls: "specific-mobile-menu-item",
        },
        contact: {
            slug: "contact",
            label: "Contact",
            mobileSection: "menu",
            externalLink: "https://www.businessleague.fr/",
            customCls: "specific-mobile-menu-item",
        },
        cookies: {
            slug: "cookies",
            label: "Cookies",
            mobileSection: "menu",
            externalLink: "javascript:openAxeptioCookies()",
            customCls: "specific-mobile-menu-item",
        },
    }
    const menuslevel2 = {
        informationsLegales: [
            {
                label: "Mentions légales",
                externalLink: "https://www.businessleague.fr/mentions-legales/",
            },
            {
                label: "Politique de données personnelles",
                externalLink: "https://www.businessleague.fr/politique-de-confidentialite/",
            },
        ],
    }

    return {
        menuslevel1,
        menuslevel2,
    }
}

export default initializeMenuBusinessVisitor
