import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import PhoneInput from "react-phone-input-2"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import { sendConfirmPhone, signUp } from "./api"
import APP_CONFIG from "../../../business/config"
import { getUsCenters, getUser } from "../../../globalAPI/api"
import useUrbanLanguage from "../../../hooks/urbanLanguage/useUrbanLanguage"
import useMediaPath from "../../../hooks/useMediaPath"
import useThemeFeatures from "../../../hooks/useThemeFeatures"
import useThemeHeader from "../../../hooks/useThemeHeader"
import phoneCountries from "../../../model/enums/phoneCountries"
import SignUpPage from "../../../pages/authentication/signUpPage/signUpPage"
import { formatTel } from "../../../services/format"
import reduceStringToLength from "../../../services/reduceStringToLength"
import resetScroll from "../../../services/resetScroll"
import { validateEmail } from "../../../services/validation"
import { toggleShowFooter } from "../../../store/actions/scaffold"
import { goNextAuthStep } from "../../../store/auth/actions/actions"
import { setFormEntry } from "../../../store/forms/actions"
import ButtonCta from "../../Buttons/button/button-cta"
import MapToggle from "../../Buttons/mapToggle/mapToggle"
import countries from "../../CountrySelection/assets/countries"
import { SelectInput } from "../../Inputs/Select/SelectInput.jsx"
import { TextInput } from "../../Inputs/TextInput/TextInput.jsx"
import Preloader from "../../loaders/preloader/preloader"
import { useThemeUpdate } from "../../ThemeContext/ThemeContext"
import { TooltipLight } from "../../tooltip/tooltipLight/tooltipLight"
import { signIn } from "../loginComponent/api"
import PasswordComplexity from "../passwordComplexity/passwordComplexity"

import "react-phone-input-2/lib/style.css"
import "./signupForm.scss"

function SignupForm() {
    const location = useLocation()
    const setTheme = useThemeUpdate()
    const themeHeader = useThemeHeader()
    const mediaPath = useMediaPath()
    const themeFeatures = useThemeFeatures()
    if (location.pathname === "/padel/signup") {setTheme("theme-padel")}
    
    const signUpPageData = useSelector(state => state.forms[SignUpPage.formName])
    const [ firstName, setFirstName ] = useState("")
    const [ lastName, setLastName ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ password, setPassword ] = useState("")
    const [ country, setCountry ] = useState(signUpPageData?.country ?
        signUpPageData.country
        :
        {
            code: "fr",
            iso: 250,
            name: "France",
        },
    )
    const [ tel, setTel ] = useState("")
    const [ complexity, setComplexity ] = useState(0)
    const [ newsLetter, setNewsLetter ] = useState(false)
    const [ padelNewsletter, setPadelNewsletter ] = useState(false)
    const [ partnerNewsletter, setPartnerNewsletter ] = useState(false)
    const [ acceptCGV, setAcceptCGV ] = useState(false)
    const [ sendingUser, setSendingUser ] = useState(false)
    const [ centersOptions, setCentersOptions ] = useState([])
    const [ selectedFavCenter, setSelectedFavCenter ] = useState(null)
    const [ error, setError ] = useState(null)
    const [ clickOnValidate, setClickOnValidate ] = useState(false)

    const recaptchaSignUpRef = useRef(null)
    const formRef = useRef(null)

    const cgvUrl = themeFeatures([ "https://www.urbansoccer.fr/mentions-legales/cgu-urbansoccer/", "https://www.urbanpadel.fr/mentions-legales/cgu-urbanpadel/" ])

    /* ACTIONS */
    const dispatch = useDispatch()
    const [ , updateState ] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])
    const { tu } = useUrbanLanguage()

    const setFormInStore = (name, data) => {
        dispatch(setFormEntry(name, data))
        forceUpdate()
    }

    useEffect(
        () => {
            resetScroll()
            initCenters()
        }, [],
    )

    useEffect(
        () => {
            if (firstName === "" &&
            lastName === "" &&
            tel === "") {
                if (signUpPageData) {
                    if (signUpPageData.firstName) {
                        setFirstName(signUpPageData.firstName)
                    }
                    if (signUpPageData.lastName) {
                        setLastName(signUpPageData.lastName)
                    }
                    if (signUpPageData.email) {
                        setEmail(signUpPageData.email)
                    }
                    if (signUpPageData.tel) {
                        setTel(signUpPageData.tel)
                    }
                    if (signUpPageData.password) {
                        setPassword(signUpPageData.password)
                    }
                    if (signUpPageData.selectedFavCenter) {
                        setSelectedFavCenter(signUpPageData.selectedFavCenter)
                    }
                    if (signUpPageData.newsLetter) {
                        setNewsLetter(signUpPageData.newsLetter)
                    }
                    if (signUpPageData.acceptCGV) {
                        setAcceptCGV(signUpPageData.acceptCGV)
                    }
                    
                }
            }
        }, [ signUpPageData ],
    )

    function initCenters() {
        let centersArr = []
        getUsCenters(themeHeader).then((res) => {
            if (res?.data) {
                const currentTheme = location?.pathname?.includes("/padel/") ? "theme-padel" : "theme-soccer"
                const padelTypes = [ 12, 14, 15 ]
                const havePadel = center => center?.resourceTypes?.some(
                    type => padelTypes.find(id => id === parseInt(type.key)),
                )
                const centersToExclude = [ 1, 38, 36, 35, 23, 18, 17, 8, 40, 21 ]
                res.data.forEach((center) => {
                    // ignore center -> id: 21, name: "Lyon-Beynost"
                    if (!centersToExclude.includes(center.id) && (currentTheme !== "theme-padel" || (currentTheme === "theme-padel" && havePadel(center)))) {
                        centersArr.push({ adresse: center. address, label: center.name,
                            lat: center.latitude, lng: center.longitude, value: center.id })
                    }
                })
            }

            const autreSiteObjIdx = centersArr.findIndex((el) => el.value === "1" || +el.value === 1)

            if (autreSiteObjIdx !== -1) {
                centersArr.push({ label: "Aucun centre", value: 1 })
            }

            setCentersOptions(centersArr)
        })
    
    } 

    function scrollFormTop() {
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
        }
    }

    function handleNotVerify() {
        if (signUpPageData && signUpPageData.accountType === 2) {
            signIn(null, null, null, signUpPageData.tokenAuth).then(() => {
                getUser().then(
                    () => {
                        setSendingUser(false)
                    },
                )
            })
        } else if (signUpPageData && signUpPageData.accountType === 1) {
            signIn(null, null, signUpPageData.tokenAuth, null).then(() => {
                getUser().then(
                    () => {
                        setSendingUser(false)
                        window.location.reload()
                    },
                )
            })
        } else {
            setSendingUser(false)
        }
    }

    async function handleSaveClick() {
        setError(null)
        if (!firstName) {
            setError("Prénom manquant")
            scrollFormTop()
            return
        } else if (!lastName) {
            setError("Nom manquant")
            scrollFormTop()
            return
        } else if (!email) {
            setError("Email manquant")
            scrollFormTop()
            return
        } else if (tel.length < 9) {
            setError("Téléphone manquant ou incorrect")
            scrollFormTop()
            return
        } else if (!selectedFavCenter) {
            setError("Centre incorrect")
            scrollFormTop()
            return
        }
        const authenticationObj = {
            type: 0,
        }

        if (signUpPageData) {
            if (signUpPageData.accountType === 0) {
                authenticationObj.login = email
                authenticationObj.password = password
            } else if (signUpPageData.accountType === 1) {
                authenticationObj.type = signUpPageData.accountType
                authenticationObj.facebookId = signUpPageData.facebookId
            } else if (signUpPageData.accountType === 2) {
                authenticationObj.type = signUpPageData.accountType
                authenticationObj.googleId = signUpPageData.googleId
            }
        } else {
            authenticationObj.login = email
            authenticationObj.password = password
        }

        let verify = 1
        if (signUpPageData) {
            if (signUpPageData.accountType === 1 || signUpPageData.accountType === 2) {
                verify = signUpPageData.telFromGoogle ? 0 : 2
            }
        }
        
        const captchaToken = await recaptchaSignUpRef.current.executeAsync()
        recaptchaSignUpRef.current.reset()
        const data = {
            authentication: authenticationObj,

            captchaToken: captchaToken,

            centerId: selectedFavCenter ? +selectedFavCenter : "",

            country: parseInt(country.iso),

            emailAddress: email,

            firstname: reduceStringToLength(firstName, 63),

            lastname: reduceStringToLength(lastName, 63),

            newsLetter: newsLetter,

            padelNewsletter: padelNewsletter,

            partnerNewsletter: partnerNewsletter,
            // birthdate: birthDate ? new Date(birthDate).toLocaleDateString("en-EN") : null,
            phoneNumber: "+" + formatTel(tel),
            verify: verify,
        }

        setClickOnValidate(true)
        setSendingUser(true)

        if (!acceptCGV) {
            setError("Accepte nos conditions générales d’utilisations")
            setSendingUser(false)
        } else {
            signUp(data, themeHeader).then((res) => {
                if (res?.data?.data?.Message) {
                    setError(res.data.data.Message)
                    scrollFormTop()
                    setSendingUser(false)
                } else {
                    localStorage.setItem("changeCoordinatesToken", res?.data?.data?.changeCoordinatesToken)
                    if (res?.data?.data?.id) {

                        if (verify === 0) {
                            handleNotVerify()
                        } else {
                            sendConfirmPhone(res.data.data.id, data.phoneNumber, res.data.data.changeCoordinatesToken, themeHeader).then(() => {
                                setError(null)
    
                                setFormInStore(
                                    SignUpPage.formName, {
                                        ...signUpPageData,
                                        acceptCGV: acceptCGV,
                                        authentication: data.authentication,
                                        country: country,
                                        email: email,
                                        firstName: firstName,
                                        id: res.data.data.id,
                                        lastName: lastName,
                                        newsLetter: newsLetter,
                                        padelNewsletter: padelNewsletter,
                                        partnerNewsletter: partnerNewsletter,
                                        password: password,
                                        selectedFavCenter: selectedFavCenter,
                                        tel: tel,
                                        verify: verify,
                                    },
                                )
                                resetScroll()
                                setSendingUser(false)
                                dispatch(goNextAuthStep())
                            })
                        }

                    } 
                }
            }).catch((err) => {
                // eslint-disable-next-line no-console
                console.log("error : ", err)
                setSendingUser(false)
            })
        }
        
    }

    return (
        <React.Fragment>
            { sendingUser &&
                <Preloader fixed={true} />
            }
            
            <div className="signupForm">
                <div className="topText">Crée un compte de façon simple et rapide afin de profiter des services d’{tu("name")}</div>
            
                <div className="form" ref={formRef}>
                    <TextInput
                        label="Prénom"
                        name="firstName"
                        value={firstName}
                        onChange={(val) => {
                            if (error === "Prénom incorrect" || error === "Prénom manquant") {
                                setError(null)
                            }
                            setFirstName(reduceStringToLength(val, 63))
                        }}
                        required
                        error={clickOnValidate && error === "Prénom incorrect"}
                        onFocus={(e) => {
                            if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                dispatch(toggleShowFooter(false))
                            }
                        }}
                        onBlur={(e) => {
                            if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                dispatch(toggleShowFooter(true))
                            }
                        }}
                        manuallyEror={error === "Prénom incorrect" || error === "Prénom manquant"}
                    />

                    <TextInput
                        label="Nom"
                        name="lastName"
                        value={lastName}
                        onChange={(val) => {
                            if (error === "Nom incorrect" || error === "Nom manquant") {
                                setError(null)
                            }
                            setLastName(reduceStringToLength(val, 63))
                        }}
                        required
                        error={clickOnValidate && error === "Nom incorrect"}
                        onFocus={(e) => {
                            if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                dispatch(toggleShowFooter(false))
                            }
                        }}
                        onBlur={(e) => {
                            if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                dispatch(toggleShowFooter(true))
                            }
                        }}
                        manuallyEror={error === "Nom incorrect" || error === "Nom manquant"}
                    />

                    <TextInput
                        label="Adresse e-mail"
                        name="email"
                        type="email"
                        inputMode="email"
                        value={email}
                        onChange={(val) => {
                            if (error === "Email manquant ou incorrect" ||
                                error === "Cet email est déjà associé à un compte UrbanSoccer" ||
                                error === "Email manquant" ||
                            (clickOnValidate && !validateEmail(email))) {
                                setError(null)
                            }
                            setEmail(val)
                        }}
                        required
                        onFocus={(e) => {
                            if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                dispatch(toggleShowFooter(false))
                            }
                        }}
                        onBlur={(e) => {
                            if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                dispatch(toggleShowFooter(true))
                            }
                        }}
                        error={error === "Email manquant ou incorrect" ||
                            error === "Cet email est déjà associé à un compte UrbanSoccer" ||
                            error === "Email manquant" || (clickOnValidate && !validateEmail(email))}
                        disabled={!email ? false : signUpPageData && signUpPageData.accountType !== 0}
                    />
                    
                    <div className={error === "Téléphone manquant ou incorrect" || error === "Ce numéro de téléphone est déjà associé à un compte UrbanSoccer" ? "phoneInput redBorder" : "phoneInput"}>
                        <PhoneInput
                            onFocus={(e) => {
                                if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                    dispatch(toggleShowFooter(false))
                                }
                            }}
                            onBlur={(e) => {
                                if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                    dispatch(toggleShowFooter(true))
                                }
                            }}
                            country={"fr"}
                            disabled={signUpPageData && signUpPageData.accountType === 2 && signUpPageData.telFromGoogle}
                            placeholder="Téléphone mobile"
                            inputProps={{
                                required: true,
                            }}
                            onlyCountries={phoneCountries}
                            enableTerritories
                            value={tel}
                            onChange={
                                (phone, data) => {
                                    if (error === "Téléphone manquant ou incorrect" || error === "Ce numéro de téléphone est déjà associé à un compte UrbanSoccer") {
                                        setError(null)
                                    }
                                    setCountry({
                                        code: data.countryCode,
                                        iso: countries.find(c => c.code === data.countryCode).iso,
                                        name: data.name,
                                    })
                                    const phoneHasDial = phone.includes(data.dialCode)
                                    if (phoneHasDial) {
                                        setTel(phone)
                                        
                                    } else {
                                        if (phone.length <= data.countryCode.length) {
                                            setTel(phone.replace(/\D|^0+/g, ""))
                                        } else {
                                            setTel(data.dialCode + phone.replace(/\D|^0+/g, ""))
                                        }
                                    }
                                }
                            }
                            specialLabel="Téléphone mobile"
                            defaultErrorMessage={error}
                            key="signupForm-phone"
                        />
                        <TooltipLight text="Ce numéro de téléphone sera utilisé pour valider ton identité et recevoir des informations transactionnelles par SMS.">
                            <img alt="" src={mediaPath([ "/assets/icons/questionMark.svg", "/assets/images/padel/icons/icons-notifications.svg" ])} className="inputPhoneQuestionMark"/>
                        </TooltipLight>
                    </div>
                    <SelectInput
                        id={"Centre"}
                        label={"Centre"}
                        options={centersOptions}
                        required
                        error={clickOnValidate && selectedFavCenter === null}
                        value={selectedFavCenter ? selectedFavCenter : ""}
                        hasAfterSelect={
                            (<MapToggle headerTitle="Choix du centre" markersData={centersOptions}
                                cls={clickOnValidate && selectedFavCenter === null ? "mapToggleError" : null}
                                closeDialogCallBack={(id) => {
                                    setSelectedFavCenter(id)
                                }}/>)
                        }
                        onChange={(event)=>{
                            if (event.target.value === "") {
                                setSelectedFavCenter(null)
                            } else {
                                if (error === "Centre incorrect") {
                                    setError(null)
                                }
                                setSelectedFavCenter(event.target.value)
                            }
                        }}
                        placeholderOption="Centre"
                    />
                    <div className="placeholder"/>
                </div>

                {(
                    (signUpPageData && signUpPageData.accountType === 0)
                    || !signUpPageData
                
                ) && (
                    <div className="v-container">
                        <PasswordComplexity
                            onPasswordChange={val => setPassword(val)}
                            onComplexityChange={data => setComplexity(data)}
                            label="Mot de passe"
                            onFocus={(e) => {
                                if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                    dispatch(toggleShowFooter(false))
                                }
                            }}
                            onBlur={(e) => {
                                if (!e.relatedTarget || (e.relatedTarget?.nodeName && e.relatedTarget.nodeName !== "INPUT")) {
                                    dispatch(toggleShowFooter(true))
                                }
                            }}
                        />
                    </div>
                )}

                <div className="newsletters">
                    <h2>Profite de cadeaux, codes promos et remises en recevant les meilleures offres et exclusivités :</h2>
                    <div className="c-inputHolder--radioSquare">
                        <input
                            type="checkbox" id="padel_exlu"
                            name="padelNewsletter" value={padelNewsletter}
                            onChange={
                                () => {
                                    setPadelNewsletter(!padelNewsletter)
                                }
                            }
                            checked={padelNewsletter}
                        />
                        <label htmlFor="padel_exlu">UrbanPadel</label>
                    </div>
                    <div className="c-inputHolder--radioSquare">
                        <input
                            type="checkbox" id="mailing_exclu"
                            name="newsletter" value={newsLetter}
                            onChange={
                                () => {
                                    setNewsLetter(!newsLetter)
                                }
                            }
                            checked={newsLetter}
                        />
                        <label htmlFor="mailing_exclu">UrbanSoccer</label>
                    </div>
                    <div className="c-inputHolder--radioSquare">
                        <input
                            type="checkbox" id="partner_exclu"
                            name="partnerNewsletter" value={partnerNewsletter}
                            onChange={
                                () => {
                                    setPartnerNewsletter(!partnerNewsletter)
                                }
                            }
                            checked={partnerNewsletter}
                        />
                        <label htmlFor="partner_exclu">Partenaires</label>
                    </div>
                </div>

                <div className="c-inputHolder--radioSquare">
                    <input
                        type="checkbox" id="condition_signup"
                        name="acceptCgvCheck" value={acceptCGV}
                        checked={acceptCGV}
                        onChange={(e) => {setAcceptCGV(e.target.checked)}}
                        className={classNames("checkbox", {
                            before: acceptCGV,
                            error: error && !acceptCGV,
                        })}
                    />
                    <label htmlFor="condition_signup">J'ai lu et j'accepte les <a className="black_link" href={cgvUrl} target="_blank" rel="noreferrer">conditions générales d'utilisation.</a> Mes données seront traitées conformément à la Protection des données et gestion des cookies que j'ai lu et accepté. Je peux me désabonner à tout moment.</label>
                </div>
                <ButtonCta 
                    className="buttonCta--submit"
                    isAlt= {true}
                    text= "Enregistrer"
                    disabled={((signUpPageData && signUpPageData.accountType === 0) || !signUpPageData) && complexity !== 4}
                    onClick={handleSaveClick}
                />
                {
                    error &&
                        (<div className="errorLabel">
                            <img alt="" className="iconError" src="/assets/icons/errorNotif.svg" />
                            <div>
                                {
                                    error
                                }
                            </div>
                        </div>)
                }
                <ReCAPTCHA
                    ref={recaptchaSignUpRef}
                    sitekey={APP_CONFIG.recapchaSiteKey}
                    size="invisible"
                />
            </div>
        </React.Fragment>
    )

}

export default SignupForm
