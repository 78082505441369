import classNames from "classnames"
import PropTypes from "prop-types"
import Skeleton from "react-loading-skeleton"
import { useLocation, useNavigate } from "react-router-dom"

import { LeaguePath } from "../../../routes/ApplicationPaths/LeaguePaths.js"
import { TeamPicture } from "../TeamPicture/TeamPicture.jsx"

import "../TeamSwitcher/TeamSwitcher.scss"

export const TeamHeading = ({ team }) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const isAlreadyOnTeamPage = pathname === LeaguePath.toLeagueTeamDetails(team?.id)

    const redirectToTeam = () => {
        if (!isAlreadyOnTeamPage) {
            navigate(LeaguePath.toLeagueTeamDetails(team.id), { state: {
                hasPreviousHistory: true,
            } })
        }
    }

    return (
        <div className={classNames("team-switcher", {
            "clickable": !isAlreadyOnTeamPage,
        })} onClick={redirectToTeam}>
            {team?.name ? <TeamPicture image={team?.blasonUrl || team?.blazon} size={48} /> : <Skeleton width={48} height={48} />}
            <div className={"team-switcher-content"}>
                <p data-testid="switchTeam" className={isAlreadyOnTeamPage ? "no-action" : null}>
                    {team?.name || <Skeleton width={240} />}
                </p>
                <span>{team?.leagueName || <Skeleton width={160} />}</span>
            </div>
        </div>
    )
}

TeamHeading.propTypes = {
    team: PropTypes.object,
}
