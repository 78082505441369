import classNames from "classnames"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

import ProgressCircle from "../../../../components/league/ProgressCircle/ProgressCircle.jsx"
import { useAssets } from "../../../../providers/AssetsProvider.jsx"
import { LeaguePath } from "../../../../routes/ApplicationPaths/LeaguePaths.js"

import "./PrizeCard.scss"

export const PrizeCard = ({ data }) => {
    const { wins, losses, draws, played } = data
    const navigate = useNavigate()
    const { m } = useAssets()

    return (
        <div
            onClick={() => navigate(LeaguePath.toLeaguePalmaresDetails(data.leagueId, data.id))}
            className={"prizeCard"}
        >
            <div className={"prizeCard__content"}>
                <h3 className={"prizeCard__title"}>
                    {data.name}
                </h3>
                <h4 className={"prizeCard__subtitle"}>
                    {data.leagueName}
                </h4>
                <div className={"prizeCard__center"}>
                    <img src={m("icons.league.center")} alt={"center"}/>
                    <span>{data.centerName}</span>
                </div>
                <a
                    className={"prizeCard__button"}
                >
                    Voir le détail
                    <img alt="button arrow" src={m("icons.buttons.linkArrowRight")}/>
                </a>
            </div>
            <div className={"prizeCard__ranking"}>
                <div className={classNames("prizeCard__ranking__position", {
                    isFirst: data.rank === 1,
                })}>
                    <span>{data.rank + (data.rank === 1 ? "er" : "ème")}</span>
                    {data.rank === 1 && (
                        <picture>
                            <img alt="first place" src={"/assets/icons/league/cup-white.svg"}/>
                        </picture>
                    )}
                </div>
                <div className="prizeCard__ranking__stats">
                    <div>
                        <ProgressCircle
                            color="main"
                            size={50}
                            strokeWidth={5}
                            fill={wins / played || 0}
                            label={{
                                number: wins,
                                text: "vict.",
                            }}
                        />
                    </div>
                    <div>
                        <ProgressCircle
                            color="secondary"
                            fill={draws / played || 0}
                            size={50}
                            strokeWidth={5}
                            label={{
                                number: draws,
                                text: "nuls",
                            }}
                        />
                        <ProgressCircle
                            color="tertiary"
                            fill={losses / played || 0}
                            size={50}
                            strokeWidth={5}
                            label={{
                                number: losses,
                                text: "déf.",
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

PrizeCard.propTypes = {
    data: PropTypes.object.isRequired,
}
