import classNames from "classnames"
import L from "leaflet"
import { any, array, arrayOf, func, node, oneOfType, string } from "prop-types"
import { useEffect, useState } from "react"
import { MapContainer, Marker, Popup, TileLayer, ZoomControl, useMap } from "react-leaflet"
import MarkerClusterGroup from "react-leaflet-cluster"

import BrowserGeolocErrorPopin from "./BrowserGeolocErrorPopin/BrowserGeolocErrorPopin"
import useThemeFeatures from "../../../hooks/useThemeFeatures"
import { useAssets } from "../../../providers/AssetsProvider.jsx"
import Preloader from "../../loaders/preloader/preloader"
import Modal from "../../Modal/Modal"
import { useTheme } from "../../ThemeContext/ThemeContext"
import ButtonCta from "../button/button-cta"

import "leaflet/dist/leaflet.css"
import "./mapToggle.scss"

function FlyTo({ coords, zoom }) {
    const map = useMap()
    map.flyTo(coords, zoom)
    return null
}

FlyTo.propTypes = {
    coords: any,
    zoom: any,
}

function MapToggle({ headerTitle, markersData, closeDialogCallBack }) {
    const theme = useTheme()
    const { m } = useAssets()
    const themeFeatures = useThemeFeatures()
    const [ locatorIcon, setLocatorIcon ] = useState(m("icons.inputs.locator"))
    // const [ locatorIcon, setLocatorIcon ] = useState(mediaPath([ "/assets/icons/cible-white.svg", "/assets/images/padel/icons/localisation.svg", "/assets/icons/cible-white.svg" ]))
    const [ modalOpen, setModalOpen ] = useState(false)
    const [ markerIcon, setMarkerIcon ] = useState(null)
    const [ coords, setCoords ] = useState([ 46.58458, 2.319530 ])
    const [ zoom, setZoom ] = useState(6)
    const [ isLoadingGeoloc, setIsLoadingGeoloc ] = useState(false)
    const [ showGeolocError, setShowGeolocError ] = useState(false)

    const locate = () => {
        setIsLoadingGeoloc(true)

        const success = position => {
            setIsLoadingGeoloc(false)
            setCoords([ position.coords.latitude, position.coords.longitude ])
            setZoom(10)
        }

        const error = () => {
            setIsLoadingGeoloc(false)
            setShowGeolocError(true)
        }
        navigator.geolocation.getCurrentPosition(success, error)
    }

    const optionsMap = {
        maxZoom: 19,
        url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
    }

    useEffect(() => {
        setLocatorIcon(m("icons.inputs.locator"))
        setMarkerIcon(L.icon({
            iconSize: themeFeatures([ [ 20, 30 ], [ 44, 47 ] ]),
            // iconUrl: mediaPath([ "/assets/icons/marker.png", "/assets/images/padel/icons/little-pin.svg", "/assets/icons/marker.png" ]),
            iconUrl: m("icons.inputs.marker"),
        }))
    }, [ theme ])

    function selectCallback(idCenter) {
        setModalOpen(false)
        if (closeDialogCallBack) {
            closeDialogCallBack(idCenter)
        }
    }

    return (
        <>
            <div className="mapToggle">
                <ButtonCta
                    key={"mapToggleButton-" + theme}
                    noSkew
                    noShadow
                    onlyIcon={true}
                    className={classNames("buttonCta--map", {
                        "targetPadel wrap": theme === "theme-padel",
                    })}
                    icon={m("icons.inputs.mapTarget")}
                    // icon={mediaPath([ "/assets/icons/cible-orange.svg", "/assets/images/padel/icons/icons-target-black.svg", "/assets/icons/cible-orange.svg" ])}
                    // hoverChangeIcon={mediaPath([ "/assets/icons/cible-white.svg", "/assets/images/padel/icons/icons-target-white.svg", "/assets/icons/cible-white.svg" ])}                    icon={mediaPath([ "/assets/icons/cible-orange.svg", "/assets/images/padel/icons/icons-target-black.svg", "/assets/icons/cible-orange.svg" ])}
                    hoverChangeIcon={m("icons.inputs.mapTargetHover")}
                    onClick={() => setModalOpen(true)}
                    disabled={!markersData || markersData.length === 0}
                >
                </ButtonCta>
            </div>
            <Modal
                withPortal
                portalIdentifier="#content"
                className={classNames("mapToggleModal", { [theme]: theme })}
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <h3 className="mapHeader">{headerTitle}</h3>

                <div
                    className="geolocateDiv"
                    onClick={locate}
                >
                    {
                        isLoadingGeoloc ?
                            <Preloader cls="loaderGeolocate"/>
                            : (
                                <img src={locatorIcon} alt=""/>
                            )
                    }
                </div>

                <MapContainer
                    id="mapid"
                    center={coords}
                    zoom={zoom}
                    zoomControl={false}
                >
                    <TileLayer url={optionsMap.url}/>

                    <MarkerClusterGroup
                        iconCreateFunction={(cluster) => {
                            const count = cluster.getChildCount()
                            return L.divIcon({
                                className: "marker-cluster",
                                html: `<div><span>${count}</span></div>`,
                                iconSize: L.point(40, 40, true),
                            })
                        }}
                    >
                        {markersData?.map(marker => ((marker.lat && marker.lng) && (
                            <Marker
                                key={`marker-${marker.lat}-${marker.lng}`}
                                position={[ marker.lat, marker.lng ]}
                                icon={markerIcon ?? ""}
                            >
                                <Popup>
                                    <div className="centerMarkerPopup">
                                        <div className="centerName">
                                            {marker.label}
                                        </div>
                                        <div className="centerData">
                                            {marker.adresse}
                                        </div>
                                        <ButtonCta
                                            isAlt={true}
                                            id={marker.value}
                                            text="Sélectionner"
                                            onClick={() => {
                                                selectCallback(marker.value)
                                            }}
                                        />
                                    </div>
                                </Popup>
                            </Marker>
                        )))}
                    </MarkerClusterGroup>
                    <ZoomControl position={themeFeatures([ "topleft", "bottomright", "topleft" ])}/>
                    <FlyTo coords={coords} zoom={zoom}/>
                </MapContainer>
                <BrowserGeolocErrorPopin isOpen={showGeolocError} show={setShowGeolocError} />
            </Modal>
        </>

    )
}

MapToggle.propTypes = {
    closeDialogCallBack: func,
    cls: string,
    coords: any,
    headerTitle: oneOfType([ node, arrayOf(node) ]),
    markersData: array,
}
export default MapToggle
