const APP_CONFIG = {
    external: {
        businessLeague: "https://businessleague.fr/contact",
    },
    padelCenterActivity: 2,
    padelDefaultTeamsNames: {
        team1: "Equipo 1",
        team2: "Equipo 2",
    },
    paymentTypes: {
        CB: 8,
        GYMLIB: 15,
    },
    recapchaSiteKey: import.meta.env.VITE_RECAPTCHA_KEY,
    schoolTypes: {
        session: import.meta.env.VITE_US_SCHOOL_TYPES_SESSION?.split(",").map(Number),
        subscription: import.meta.env.VITE_US_SCHOOL_TYPES_SUBSCRIPTION?.split(",").map(Number),
    },
    themes: {
        business: "business",
        padel: "padel",
        soccer: "soccer",
    },
}

export default APP_CONFIG
