
import dayjs from "dayjs"

import { LeaguePath } from "../../../routes/ApplicationPaths/LeaguePaths.js"
import { getTeamPJT } from "../api/leagueTeam.js"
import { getLeagueRanking } from "../api/myLeague.js"

export const findNextWeek = (games, teamFilter) => {
    const currentDate = dayjs()
    return Object.entries(games)
        .map(([ week, matches ]) => ({
            matches: matches
                .filter(match => !teamFilter || match.team1.teamId === teamFilter || match.team2.teamId === teamFilter),
            week,
        }))
        .reduce((closest, { week, matches }) => {
            const upcomingMatches = matches.filter(match => dayjs(match.date).isAfter(currentDate))
            if (upcomingMatches.length > 0) {
                const nextMatchDate = dayjs(upcomingMatches[0].date)
                if (!closest.week || nextMatchDate.isBefore(dayjs(games[closest.week][0].date))) {
                    return { date: nextMatchDate, week }
                }
            }
            return closest
        }, { date: null, week: null }).week
}

export const hasTeamChanged = (leagueId, teamId, selectedTeamData) => {
    return selectedTeamData?.leagueId !== leagueId || selectedTeamData?.id !== teamId
}

export const navigateToPlayerPage = (navigate, playerId, hash, teamId) => {
    const path = hash ? LeaguePath.toLeaguePlayerDetails(teamId, playerId) + `#${hash}` :
        LeaguePath.toLeaguePlayerDetails(teamId, playerId)

    navigate(path, {
        state: {
            hasPreviousHistory: true,
        },
    })
}

export const navigateToTeamPage = (navigate, teamId) => {
    navigate(LeaguePath.toLeagueTeamDetails(teamId), {
        state: {
            hasPreviousHistory: true,
        },
    })
}

export const navigateToGameDetails = (navigate, teamId, gameId, state) => {
    navigate(LeaguePath.toLeagueMatchDetails(teamId, gameId), {
        state: {
            ...state,
            hasPreviousHistory: true,
        },
    })

}

export async function getTeamsRanking(leagueId, additionalData = null)  {
    const { generalRanking, scorersRanking, league } = await getLeagueRanking(leagueId)

    return {
        ...additionalData,
        league: league,
        ranking: generalRanking?.map((team) => {
            const values = new Map()

            Object.entries(team).forEach(([ key, value ]) => {
                values.set(key, value)
            })

            return ({
                id: team.id,
                values,

            })
        }) ?? [],
        scorers: scorersRanking?.map((player) => {
            const values = new Map()

            Object.entries(player).forEach(([ key, value ]) => {
                values.set(key, value)
                values.set("name", player.firstname + " " + player.lastname)
            })

            return ({
                id: player.id,
                values,
            })
        }) ?? [],
    }
}

export const prepareVideoData = (data, limit, name) => {
    if (!data?.videos?.length) { return [] }

    let teamName = data.visitorTeam.team

    const videos = data.videos.map(video => ({
        ...video,
        angles: video.angles,
        name: name(teamName, video.type === "match" ? "Match complet" : getScoreLabel(video), video),
        thumbnail: video.details?.thumb ?? video.angles[0]?.miniatureUrl ?? "",
    }))

    return limit > 0 ? videos.slice(0, limit) : videos
}

export const getScoreLabel = (video) => {
    if (video.details.localTeam.scoreTeam === null || video.details.visitorTeam.scoreTeam === null) {
        return "But"
    } else {
        return `${video.details.localTeam.scoreTeam}-${video.details.visitorTeam.scoreTeam}`
    }
}

export const attachMvpVotesToPlayers = game => {
    const gamePassed = dayjs().isAfter(dayjs(game.date))
    if (!gamePassed) {return game}

    return ({
        ...game,
        players: game.players.map(player => player.status === 1 ? {
            ...player,
            currentNbVotes: (game?.mvpInfos?.players || []).find(mvpPlayer => mvpPlayer.id === player.id)?.currentNbVotes ?? 0,
            filterForMvp: true,
        } : player),
    })
}

export const getMvpPlayer = game => {
    const mvpPlayer = game?.localTeam?.mvpInfo
    const gameUser = (game?.mvpInfos?.players || []).find(player => player.championshipPlayerId === mvpPlayer?.mvpId)

    return gameUser ? {
        ...mvpPlayer,
        ...gameUser,
    } : null
}

export const isGameForfeit = game => game?.team1?.withdrawalTeam || game?.team2?.withdrawalTeam

export const determineForfeitMessage = (game, teamId, opponentOnly) => {
    if (opponentOnly) {
        return ((game?.team1.withdrawalTeam && game?.team1.teamId !== teamId) || (game?.team2.withdrawalTeam && game?.team2.teamId !== teamId)) ? "Forfait" : null
    }

    if ((game?.team1.withdrawalTeam && game?.team1.teamId === teamId) || (game?.team2.withdrawalTeam && game?.team2.teamId === teamId)) {
        return "Ton équipe a déclaré forfait"
    } else {
        return  "Ton adversaire a déclaré forfait"
    }
}

export const isMvpVoteOpen = game => {
    const gamePlayed = dayjs().isAfter(dayjs(game.date))
    const gameFinished = dayjs().isBefore(dayjs(game.date).add(24, "hours"))
    const gameStatusPlayed = game?.statusInt === 1
    const mvpInfosAvailable = !!game?.mvpInfos && game?.mvpInfos !== "closed"

    return gamePlayed && gameFinished && gameStatusPlayed && mvpInfosAvailable
}

export const resolveTeamPJT = (team, theme, user) => {
    if (!team?.id) {return}

    const generateShareLink = (pjt) => {
        return window.location.origin
            + "?action=tokenActivation&invitation=TYPE_LEAGUE_TEAM&theme="
            + theme
            + "&id="
            + team.id
            + "&PJT="
            + encodeURIComponent(pjt)
            + "&fromUser="
            + encodeURIComponent((user.firstname + " " + user.lastname))
    }

    if (!team?.publicJoinToken) {
        getTeamPJT(team.id).then((data) => {
            return generateShareLink(data)
        })
    } else {
        return generateShareLink(team.publicJoinToken)
    }
}

export const prepareVideosFromGroup = (goalsItems, formatNameGroup) => {
    return (goalsItems || [])?.map((group) => {
        return ({
            angles: (group?.angles?.filter(v => !!v.url) || []).map((video, index) => ({
                name: `Angle ${index + 1}`,
                thumbnail: video.miniatureUrl,
                url: video.url,
            })),
            name: formatNameGroup(group),
            thumbnail: group?.details?.thumb ?? group.angles[0]?.miniatureUrl ?? "",
        })
    })
}

export const getDayNumberFromDayLabel = (dayLabel) => {
    return [ "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche" ].indexOf(dayLabel)
}

export function removeLeadingSpaces(str) {
    return str ?  str?.replace(/^[ \t]+/, "") : null
}
